import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import "../scss/Thanks.scss";
import { Helmet } from "react-helmet";

export default function Thanks() {
  const data = useStaticQuery(graphql`
    query MyQuery3790 {
      allContentfulImage (filter: { name: { eq: "thankyou" } }){
        edges {
          node {
            id
            name
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
    
  `);

  return (
    <div className="ThankYouPageContainer">
      <div className="contentContainerThanks">
        <div className="imageContainerThanks">
          <img className="Thanks" src={data.allContentfulImage.edges[0].node.image.file.url} />
        </div>
        <div className="ThanksContainer">
            <p className="thankYouTitle">Thank You <br></br>For Signing up to LUXE </p>
            <p className="thankYouText">You are a few steps away from making <strong>smarter luxury business and investment decisions.</strong></p>
            <p className="thankYouText">We’ve sent an email  with a verification link to the Business or Educational Email you have provided.</p>
            <p className="thankYouText">Please verify it’s you via your email.</p>
        </div>
      </div>
    </div>

  );
}

